/* eslint-disable react-hooks/exhaustive-deps */
// React Dependencies
import React, { useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import styled from 'styled-components';

// Types
import { ConfigDataSuccess } from '../../react-query/types';

// Redux
import { useDispatch } from 'react-redux';
import { addNotification } from '../../redux/actions/notification';
import { removePopup, setPopup } from '../../redux/actions/popup';
import { setModal } from '../../redux/actions/modal';

// Layouts
import { ErrorModalLayout, ModalLayout } from './modal-layout';

// Queries
import useFetchResource from '../../react-query/hooks/use-fetch-resource';
import useDeleteResource from '../../react-query/hooks/use-delete-resource';
import usePatchMultiResourceWithPayload from '../../react-query/hooks/use-patch-multi-resource-with-payload';

// Configurations
import { CONFIG_BLACKLIST_IP, CONFIG_BLACKLIST_LEVEL } from '../../configurations/resources-config';

// Components
import ConfigTable from '../../components/tables/config-table/config-table';
import Form from '../../components/forms/form';
import { FormStatus } from '../../components/forms/context/form-context';
import { TooltipPopUpSide } from '../../components/tooltip';
import validators from '../../components/forms/validation/validators';

// Enums
import { NotificationMessageType } from '../../enums/notification-types';
import { ButtonThemes } from '../../enums/button-themes';

// Helpers
import { generatePath } from '../../helpers/request-builder';
import { TableFilter } from '../../components/tables/config-table/helpers/table-filter';

// Styles
const StyledFormContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 20px;
    margin-top: 20px;
`;

const StyledFormTitle = styled.h3`
    margin: 0 0 10px 0;
`;

const StyledFormText = styled.p`
    margin: 0 0 30px 0;
`;

// Types
type IpBlacklistRule = {
    id: number;
    minIp: string;
    maxIp: string;
    level: number;
    levelName: string;
};

type IpBlacklistRuleData = IpBlacklistRule & {
    selected: boolean;
};

type Option = {
    label: string;
    value: string;
    selected?: boolean;
};

const LayoutModalManageIpBlacklist = () => {
    const dispatch = useDispatch();

    // IP blacklist data
    const [ipBlacklistRuleData, setIpBlacklistRuleData] = useState<IpBlacklistRuleData[]>([]);
    const [filteredIpBlacklistRuleData, setFilteredIpBlacklistRuleData] = useState<IpBlacklistRuleData[]>([]);
    const [ipBlacklistRuleDataToDelete, setIpBlacklistRuleDataToDelete] = useState<number[]>([]);
    const [ipBlacklistRuleDataToEdit, setIpBlacklistRuleDataToEdit] = useState<IpBlacklistRule>();

    // IP blacklist level
    const [ipBlacklistLevelOptions, setIpBlacklistLevelOptions] = useState<Option[]>([]);

    // Table States
    const [searchValue, setSearchValue] = useState('');
    const [disableTable, setDisableTable] = useState(false);

    // Queries & Mutations
    const ipBlacklistQuery = useFetchResource({
        resource: CONFIG_BLACKLIST_IP,
        params: [{ key: 'active', value: 1 }],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(rule => {
                return {
                    id: rule.id,
                    minIp: rule.ip_min,
                    maxIp: rule.ip_max,
                    level: rule.level.id,
                    levelName: rule.level.name,
                    selected: false,
                };
            });
        },
    });

    const ipBlacklistLevelQuery = useFetchResource({
        resource: CONFIG_BLACKLIST_LEVEL,
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(level => {
                return {
                    value: level.id,
                    label: level.name,
                };
            });
        },
    });

    const ipBlacklistRuleDataDeleteMutation = useDeleteResource({
        resource: CONFIG_BLACKLIST_IP,
        resourceIds: ipBlacklistRuleDataToDelete.map(id => id.toString()),
        handleOnSuccess: () => {
            setDisableTable(false);
            setSearchValue('');
            ipBlacklistQuery.refetch();
            dispatch(
                addNotification({
                    copy: 'Blacklist Ip successfully deleted.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            setDisableTable(false);
            setSearchValue('');
            dispatch(
                addNotification({
                    copy: 'There was an issue while deleting your Blacklist Ips. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const ipBlacklistRuleDataPatchMutation = usePatchMultiResourceWithPayload({
        resource: CONFIG_BLACKLIST_IP,
        handleOnSuccess: () => {
            setIpBlacklistRuleDataToEdit(undefined);
            setDisableTable(false);
            setSearchValue('');
            setFilteredIpBlacklistRuleData(
                filteredIpBlacklistRuleData.map(rule => ({
                    ...rule,
                    selected: false,
                }))
            );

            dispatch(
                addNotification({
                    copy: 'IP Blacklist rule updated successfully.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            setSearchValue('');
            dispatch(
                addNotification({
                    copy: 'There was an issue while saving your IP Blacklist rule. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    // Refetch data when the modal is opened
    useEffect(() => {
        ipBlacklistQuery.refetch();
    }, []);

    // Fetch Fixed Blacklist rule data
    useEffect(() => {
        if (ipBlacklistQuery.data) {
            setIpBlacklistRuleData(ipBlacklistQuery.data);
            setFilteredIpBlacklistRuleData(ipBlacklistQuery.data);
            setSearchValue('');
        }
    }, [ipBlacklistQuery.data]);

    // Fetch Fixed Blacklist level data
    useEffect(() => {
        if (ipBlacklistLevelQuery.data) {
            setIpBlacklistLevelOptions(ipBlacklistLevelQuery.data);
        }
    }, [ipBlacklistLevelQuery.data]);

    // Update the deleted Fixed Blacklist rule data
    useEffect(() => {
        setIpBlacklistRuleDataToDelete(filteredIpBlacklistRuleData.filter(rule => rule.selected).map(rule => rule.id));
    }, [filteredIpBlacklistRuleData]);

    // Handelers
    const handleTableSearch = (searchValue: string) => {
        if (disableTable) return;
        setSearchValue(searchValue);
        const keysToFilter = ['minIp', 'maxIp', 'levelName'] as (keyof IpBlacklistRuleData)[];
        setFilteredIpBlacklistRuleData(
            TableFilter(ipBlacklistRuleData, searchValue, keysToFilter) as IpBlacklistRuleData[]
        );
    };

    const handleCheckboxChange = (rowId: number) => {
        setFilteredIpBlacklistRuleData(
            filteredIpBlacklistRuleData.map(rule => {
                if (rule.id === rowId) {
                    return {
                        ...rule,
                        selected: !rule.selected,
                    };
                }

                return rule;
            })
        );
    };

    const handleSingleIpBlacklistRuleEditClick = (rowId: number) => {
        const blacklistRule = filteredIpBlacklistRuleData.find(rule => rule.id === rowId);

        if (blacklistRule) {
            const blacklistRuleData = {
                id: blacklistRule.id,
                minIp: blacklistRule.minIp,
                maxIp: blacklistRule.maxIp,
                level: blacklistRule.level,
                levelName: blacklistRule.levelName,
            };

            setDisableTable(true);
            setIpBlacklistRuleDataToEdit(blacklistRuleData);
        }
    };

    const handleSingleIpBlacklistRuleDeleteClick = (rowId: number) => {
        setIpBlacklistRuleDataToDelete([rowId]);

        const handleDisardClick = () => {
            setIpBlacklistRuleDataToDelete([]);
            dispatch(removePopup());
        };

        const handleDelete = () => {
            setDisableTable(true);
            ipBlacklistRuleDataDeleteMutation.mutate();
            dispatch(removePopup());
        };

        dispatch(
            setPopup({
                title: 'Delete',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: `Are you sure you would like to delete this Blacklist IP?`,
                },
                buttons: [
                    {
                        onClick: handleDelete,
                        value: 'YES, DELETE',
                    },
                    {
                        onClick: handleDisardClick,
                        value: 'CANCEL',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    const handleBulkDeleteClick = () => {
        if (ipBlacklistRuleDataToDelete.length === 0) return;

        const handleDisardClick = () => {
            dispatch(removePopup());
        };

        const handleDelete = () => {
            setFilteredIpBlacklistRuleData(
                filteredIpBlacklistRuleData.map(rule => {
                    return {
                        ...rule,
                        selected: !rule.selected,
                    };
                })
            );
            setDisableTable(true);
            ipBlacklistRuleDataDeleteMutation.mutate();
            dispatch(removePopup());
        };

        dispatch(
            setPopup({
                title: 'Delete',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: `Are you sure you would like to delete the selected Blacklist IPs?`,
                },
                buttons: [
                    {
                        onClick: handleDelete,
                        value: 'YES, DELETE',
                    },
                    {
                        onClick: handleDisardClick,
                        value: 'CANCEL',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    const handleSubmitForm = (data: FieldValues) => {
        const formData = data;

        if (
            formData.level !== ipBlacklistRuleDataToEdit?.level ||
            formData.minIp !== ipBlacklistRuleDataToEdit?.minIp ||
            formData.maxIp !== ipBlacklistRuleDataToEdit?.maxIp
        ) {
            setIpBlacklistRuleDataToEdit(undefined);

            const payload: FieldValues = {
                resource_uri: generatePath('config', 'blacklist-ip', formData.id),
                level: generatePath('config', 'blacklist-level', formData.level),
                ip_min: formData.minIp,
                ip_max: formData.maxIp,
            };

            ipBlacklistRuleDataPatchMutation.mutate({
                payload: [payload],
                resourceId: [formData?.id.toString()],
            });
        }
    };

    const handleEditFormCloseClick = () => {
        setIpBlacklistRuleDataToEdit(undefined);
        setFilteredIpBlacklistRuleData(
            filteredIpBlacklistRuleData.map(rule => ({
                ...rule,
                selected: false,
            }))
        );
        setDisableTable(false);
    };

    const handleCreateBlacklistRuleClick = () => {
        dispatch(setModal('CreateIPBlacklistRule', {}));
    };

    if (ipBlacklistQuery.status === 'success' || ipBlacklistQuery.status === 'pending') {
        return (
            <ModalLayout modalHeader="Manage IP Blacklist">
                <p>Manage previously configured blacklist rules below.</p>

                <ConfigTable
                    status={ipBlacklistQuery.status}
                    isFetching={ipBlacklistQuery.isFetching}
                    disabled={disableTable}
                    empty={filteredIpBlacklistRuleData.length === 0}
                >
                    <ConfigTable.TableSearch
                        searchValue={searchValue}
                        onTableSearch={handleTableSearch}
                        isDisabled={disableTable}
                    />
                    <ConfigTable.Table maxHeight="400px">
                        <ConfigTable.Header>
                            <ConfigTable.Row key="thead">
                                <ConfigTable.CellHeader />
                                <ConfigTable.CellHeader>Minimum IP</ConfigTable.CellHeader>
                                <ConfigTable.CellHeader>Maximum IP</ConfigTable.CellHeader>
                                <ConfigTable.CellHeader>Level</ConfigTable.CellHeader>
                                <ConfigTable.CellHeader />
                            </ConfigTable.Row>
                        </ConfigTable.Header>
                        <ConfigTable.Body>
                            {filteredIpBlacklistRuleData.map(rule => {
                                return (
                                    <ConfigTable.Row key={rule.id}>
                                        <ConfigTable.CellCheckbox
                                            rowId={rule.id}
                                            checked={rule.selected}
                                            onCheckedChange={handleCheckboxChange}
                                        />
                                        <ConfigTable.Cell>{rule.minIp}</ConfigTable.Cell>
                                        <ConfigTable.Cell>{rule.maxIp}</ConfigTable.Cell>
                                        <ConfigTable.Cell>{rule.levelName}</ConfigTable.Cell>
                                        <ConfigTable.CellActions>
                                            <ConfigTable.ActionDropdownItem
                                                rowId={rule.id}
                                                type="edit"
                                                onClick={handleSingleIpBlacklistRuleEditClick}
                                            />
                                            <ConfigTable.ActionDropdownItem
                                                rowId={rule.id}
                                                type="delete"
                                                onClick={handleSingleIpBlacklistRuleDeleteClick}
                                            />
                                        </ConfigTable.CellActions>
                                    </ConfigTable.Row>
                                );
                            })}
                        </ConfigTable.Body>
                    </ConfigTable.Table>
                    <ConfigTable.ActionBar>
                        <ConfigTable.ActionBarJustifyLeft>
                            <ConfigTable.ActionButton
                                type="delete"
                                label="Delete Blacklist Rule"
                                onClick={handleBulkDeleteClick}
                                isDisabled={ipBlacklistRuleDataToDelete.length === 0}
                            />
                        </ConfigTable.ActionBarJustifyLeft>
                        <ConfigTable.ActionBarJustifyRight>
                            <ConfigTable.ActionButton
                                type="add"
                                label="Create New Blacklist Rule"
                                onClick={handleCreateBlacklistRuleClick}
                                isDisabled={disableTable}
                            />
                        </ConfigTable.ActionBarJustifyRight>
                    </ConfigTable.ActionBar>
                </ConfigTable>

                {/* Edit form */}
                {ipBlacklistRuleDataToEdit && (
                    <StyledFormContainer>
                        <StyledFormTitle>Edit Rule</StyledFormTitle>
                        <StyledFormText>
                            More information about how what each level will affect your data can be found on our{' '}
                            <a
                                href="http://tag.docs.withcubed.com/onboarding/general/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                support page
                            </a>
                            .
                        </StyledFormText>

                        <Form
                            status={ipBlacklistLevelQuery.status as FormStatus}
                            onSubmit={handleSubmitForm}
                            defaultValues={ipBlacklistRuleDataToEdit}
                        >
                            <Form.Body>
                                <Form.Section>
                                    <Form.Field>
                                        <Form.InputLabel
                                            htmlFor="minIp"
                                            label="Minimum IP"
                                            required={true}
                                            tooltipCopy="This will determine at what point the Cubed system will ignore hits made from this IP range."
                                            tooltipPosition={TooltipPopUpSide.Top}
                                        />
                                        <Form.InputText
                                            name="minIp"
                                            placeholder="Minimum IP..."
                                            validators={[validators.required]}
                                        />
                                    </Form.Field>

                                    <Form.Field>
                                        <Form.InputLabel
                                            htmlFor="maxIp"
                                            label="Maximum IP"
                                            required={true}
                                            tooltipCopy="This will determine at what point the Cubed system will ignore hits made from this IP range."
                                            tooltipPosition={TooltipPopUpSide.Top}
                                        />
                                        <Form.InputText
                                            name="maxIp"
                                            placeholder="Maximum IP..."
                                            validators={[validators.required]}
                                        />
                                    </Form.Field>

                                    <Form.Field>
                                        <Form.InputLabel
                                            htmlFor="level"
                                            label="Level"
                                            required={true}
                                            tooltipCopy="Define what to do with visits from your defined blacklist."
                                            tooltipPosition={TooltipPopUpSide.Top}
                                        />
                                        <Form.InputSelect
                                            name="level"
                                            options={ipBlacklistLevelOptions}
                                            placeholder="Level..."
                                            validators={[validators.required]}
                                        />
                                    </Form.Field>
                                </Form.Section>
                            </Form.Body>
                            <Form.Footer>
                                <Form.InputButton
                                    value="Save"
                                    loading={ipBlacklistRuleDataPatchMutation.isPending}
                                    disabled={ipBlacklistRuleDataPatchMutation.isPending}
                                />
                                <Form.InputButton
                                    value="Cancel"
                                    type="button"
                                    onClick={handleEditFormCloseClick}
                                    loading={ipBlacklistRuleDataPatchMutation.isPending}
                                    disabled={ipBlacklistRuleDataPatchMutation.isPending}
                                />
                            </Form.Footer>
                        </Form>
                    </StyledFormContainer>
                )}
            </ModalLayout>
        );
    }

    // Page Error
    return <ErrorModalLayout modalHeader="Manage IP Blacklist" />;
};

export default LayoutModalManageIpBlacklist;
